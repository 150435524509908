import React from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles";
import PageWrapper from "../../../../components/src/Pagewrapper.web";
import { Divider, Modal,Table,Select } from "antd";
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Slider from '@material-ui/core/Slider';
import {Assessmentdata,AssessmentTest5,AssessmentPitsburg, AssessmentPitsburg2, AssessmentPitsburg3, AssessmentPitsburg4} from "../AssessmentQuestions/AssessmentQuestionsController"
const dropdown = <ExpandMoreOutlinedIcon />;

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AssessmentQuestionsController, {
  Props,
} from "./AssessmentQuestionsController";
import { AnswerI, Question } from "../IAssessment";

export default class AssessmentQuestions extends AssessmentQuestionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const Client= localStorage.getItem("userType") === "client"
    this.title= Client ? "My Assessments" : "Assessment"
    if(this.state.userType !=="client"){
      this.apiAdminFunctionalPain();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  title;
  options = [
    { key: 1, value: '1', label: '01:00' },
    { key: 1, value: '2', label: '02:00' },
    { key: 1, value: '3', label: '03:00' },
    { key: 1, value: '4', label: '04:00' },
    { key: 1, value: '5', label: '05:00' },
    { key: 1, value: '6', label: '06:00' },
    { key: 1, value: '7', label: '07:00' },
    { key: 1, value: '8', label: '08:00' },
    { key: 1, value: '9', label: '09:00' },
    { key: 1, value: '10', label: '10:00' },
    { key: 1, value: '11', label: '11:00' },
    { key: 1, value: '12', label: '12:00' },
    { key: 1, value: '13', label: '13:00' },
    { key: 1, value: '14', label: '14:00' },
    { key: 1, value: '15', label: '15:00' },
    { key: 1, value: '16', label: '16:00' },
    { key: 1, value: '17', label: '17:00' },
    { key: 1, value: '18', label: '18:00' },
    { key: 1, value: '19', label: '19:00' },
    { key: 1, value: '20', label: '20:00' },
    { key: 1, value: '21', label: '21:00' },
    { key: 1, value: '22', label: '22:00' },
    { key: 1, value: '23', label: '23:00' },
    { key: 1, value: '24', label: '24:00' },
  ];
  options2 = [
    { key: 2, value: '0', label: '< 15 minutes' },
    { key: 2, value: '1', label: '16-30 minutes' },
    { key: 2, value: '2', label: '31-60 minutes' },
    { key: 2, value: '3', label: '> 60 minutes' },
  ];
  options3 = [
    { key: 3, value: '1', label: '01:00' },
    { key: 3, value: '2', label: '02:00' },
    { key: 3, value: '3', label: '03:00' },
    { key: 3, value: '4', label: '04:00' },
    { key: 3, value: '5', label: '05:00' },
    { key: 3, value: '6', label: '06:00' },
    { key: 3, value: '7', label: '07:00' },
    { key: 3, value: '8', label: '08:00' },
    { key: 3, value: '9', label: '09:00' },
    { key: 3, value: '10', label: '10:00' },
    { key: 3, value: '11', label: '11:00' },
    { key: 3, value: '12', label: '12:00' },
    { key: 3, value: '13', label: '13:00' },
    { key: 3, value: '14', label: '14:00' },
    { key: 3, value: '15', label: '15:00' },
    { key: 3, value: '16', label: '16:00' },
    { key: 3, value: '17', label: '17:00' },
    { key: 3, value: '18', label: '18:00' },
    { key: 3, value: '19', label: '19:00' },
    { key: 3, value: '20', label: '20:00' },
    { key: 3, value: '21', label: '21:00' },
    { key: 3, value: '22', label: '22:00' },
    { key: 3, value: '23', label: '23:00' },
    { key: 3, value: '24', label: '24:00' },
  ];
  options4 = [
    { key: 4, value: '1', label: '01:00' },
    { key: 4, value: '2', label: '02:00' },
    { key: 4, value: '3', label: '03:00' },
    { key: 4, value: '4', label: '04:00' },
    { key: 4, value: '5', label: '05:00' },
    { key: 4, value: '6', label: '06:00' },
    { key: 4, value: '7', label: '07:00' },
    { key: 4, value: '8', label: '08:00' },
    { key: 4, value: '9', label: '09:00' },
    { key: 4, value: '10', label: '10:00' },
    { key: 4, value: '11', label: '11:00' },
    { key: 4, value: '12', label: '12:00' },
    { key: 4, value: '13', label: '13:00' },
    { key: 4, value: '14', label: '14:00' },
    { key: 4, value: '15', label: '15:00' },
    { key: 4, value: '16', label: '16:00' },
    { key: 4, value: '17', label: '17:00' },
    { key: 4, value: '18', label: '18:00' },
    { key: 4, value: '19', label: '19:00' },
    { key: 4, value: '20', label: '20:00' },
    { key: 4, value: '21', label: '21:00' },
    { key: 4, value: '22', label: '22:00' },
    { key: 4, value: '23', label: '23:00' },
    { key: 4, value: '24', label: '24:00' },
  ];

  marks = [
    { value: 0, label: <FunctionalSpanFirst >0</FunctionalSpanFirst>},
    { value: 1, label: '0.25-2.75' },
    { value: 2, label: '3' },
    { value: 3, label: '4' },
    { value: 4, label: '5' },
    { value: 5, label: '6' },
    { value: 6, label: '7' },
    { value: 7, label: '8-9' },
    { value: 8, label: <FunctionalSpanLast>10</FunctionalSpanLast> },
  ];

  columns:any = [
		{
			title: (<SpanName>Name Of Problem</SpanName>),
			width: 260,
			ellipsis: true,
			render: (event: Assessmentdata) => (
				<TableData>
          <span style={{marginRight:"0.625rem"}}> {event.key}.</span>
					{event.name_of_problem}
				</TableData>
			),
		},
		{
			title: (<Span>Not at all</Span>),
			width: 180,
			ellipsis: true,
			render: (event: Assessmentdata) => (
        <div style={{display:"flex",justifyContent:"center",width:"30%"}}>
				<StyledRadioButton
          selected={event.not_at_all}
          onClick={this.handleSelect.bind(this,event.key,"not_at_all",0)}
        >
          0
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span>Several Days</Span>),
			width: 150,
			ellipsis: true,
      render: (event: Assessmentdata) => (
        <div style={{display:"flex",justifyContent:"center",width:"50%"}}>
				<StyledRadioButton
          selected={event.several_days}
          onClick={this.handleSelect.bind(this,event.key,"several_days",1)}
        >
          1
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span>More than half the days</Span>),
			width: 80,
			ellipsis: true,
      render: (event: Assessmentdata) => (
        <div style={{display:"flex",justifyContent:"center",width:"60%"}}>
				<StyledRadioButton
          selected={event.more_than_half_day}
          onClick={this.handleSelect.bind(this,event.key,"more_than_half_day",2)}
        >
          2
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span>Nearly Everyday</Span>),
			width: 50,
			ellipsis: true,
      render: (event: Assessmentdata) => (
        <div style={{display:"flex",justifyContent:"center",width:"60%"}}>
				<StyledRadioButton
          selected={event.nearly_everyday}
          onClick={this.handleSelect.bind(this,event.key,"nearly_everyday",3)}
        >
          3
        </StyledRadioButton>
        </div>
			),
		},
    
	];
  pitsburgColumn5:any = [
		{
			title: (<SpanName1>During the past month, how often have you had trouble sleeping because you...</SpanName1>),
			width: 200,
			ellipsis: true,
			render: (event: AssessmentPitsburg) => (
				<TableData>
					{event.name_of_problem}
				</TableData>
			),
		},
		{
			title: (<Span1>Not during past month</Span1>),
			width: 120,
			ellipsis: true,
			render: (event: AssessmentPitsburg) => (
        <div style={{display:"flex",justifyContent:"center",width:"60%"}}>
				<StyledRadioButton
          selected={event.not_during_past_month}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"not_during_past_month",0,"fifth")}
        >
          0
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>less than once a week</Span1>),
			width: 120,
			ellipsis: true,
      render: (event: AssessmentPitsburg) => (
        <div style={{display:"flex",justifyContent:"center",width:"50%"}}>
				<StyledRadioButton
          selected={event.less_than_once_a_week}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"less_than_once_a_week",1,"fifth")}
        >
          1
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>One or twice a week</Span1>),
			width: 120,
			ellipsis: true,
      render: (event: AssessmentPitsburg) => (
        <div style={{display:"flex",justifyContent:"center",width:"70%"}}>
				<StyledRadioButton
          selected={event.one_or_twice_a_week}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"one_or_twice_a_week",2,"fifth")}
        >
          2
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>Three or more  times a week</Span1>),
			width: 130,
			ellipsis: true,
      render: (event: AssessmentPitsburg) => (
        <div style={{display:"flex",justifyContent:"center",width:"60%"}}>
				<StyledRadioButton
          selected={event.three_or_more_times_a_week}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"three_or_more_times_a_week",3,"fifth")}
        >
          3
        </StyledRadioButton>
        </div>
			),
		},
    
	];
  pitsburgColumn6:any = [
		{
			title: (<SpanName1></SpanName1>),
			width: 200,
      ellipsis: true,
			render: (event: AssessmentPitsburg2) => (
				<TableData>
					{event.name_of_problem}
				</TableData>
			),
		},
		{
			title: (<Span1>No problem at all</Span1>),
			width: 120,
      ellipsis: true,
			render: (event: AssessmentPitsburg2) => (
        <div style={{display:"flex",justifyContent:"center",width:"60%"}}>
				<StyledRadioButton
          selected={event.no_problem_at_all}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"no_problem_at_all",0,"six")}
        >
          0
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>Only a very slight problem</Span1>),
			width: 130,
			ellipsis: true,
      render: (event: AssessmentPitsburg2) => (
        <div style={{display:"flex",justifyContent:"center",width:"50%"}}>
				<StyledRadioButton
          selected={event.only_a_very_slight_problem}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"only_a_very_slight_problem",1,"six")}
        >
          1
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>Some what of a problem</Span1>),
			width: 120,
			ellipsis: true,
      render: (event: AssessmentPitsburg2) => (
        <div style={{display:"flex",justifyContent:"center",width:"70%"}}>
				<StyledRadioButton
          selected={event.somewhat_of_a_problem}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"somewhat_of_a_problem",2,"six")}
        >
          2
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>A very big problem</Span1>),
			width: 120,
			ellipsis: true,
      render: (event: AssessmentPitsburg2) => (
        <div style={{display:"flex",justifyContent:"center",width:"60%"}}>
				<StyledRadioButton
          selected={event.a_very_big_problem}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"a_very_big_problem",3,"six")}
        >
          3
        </StyledRadioButton>
        </div>
			),
		},
    
	];
  pitsburgColumn7:any = [
		{
			title: (<SpanName1></SpanName1>),
			width: 140,
			ellipsis: true,
			render: (event: AssessmentPitsburg3) => (
				<TableData>
					{event.name_of_problem}
				</TableData>
			),
		},
		{
			title: (<Span4>Very good</Span4>),
			width:80,
			render: (event: AssessmentPitsburg3) => (
        <div style={{display:"flex",justifyContent:"center",width:"40%"}}>
				<StyledRadioButton
          selected={event.very_good}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"very_good",0,"seven")}
        >
          0
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>Fairly good</Span1>),
			width: 80,
			ellipsis: true,
      render: (event: AssessmentPitsburg3) => (
        <div style={{display:"flex",justifyContent:"center",width:"40%"}}>
				<StyledRadioButton
          selected={event.fairly_good}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"fairly_good",1,"seven")}
        >
          1
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>Fairly bad</Span1>),
			width: 80,
			ellipsis: true,
      render: (event: AssessmentPitsburg3) => (
        <div style={{display:"flex",justifyContent:"center",width:"30%"}}>
				<StyledRadioButton
          selected={event.fairly_bad}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"fairly_bad",2,"seven")}
        >
          2
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>Very bad</Span1>),
			width: 80,
			ellipsis: true,
      render: (event: AssessmentPitsburg3) => (
        <div style={{display:"flex",justifyContent:"center",width:"30%"}}>
				<StyledRadioButton
          selected={event.very_bad}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"very_bad",3,"seven")}
        >
          3
        </StyledRadioButton>
        </div>
			),
		},
    
	];
  pitsburgColumn8:any = [
		{
			title: (<SpanName1></SpanName1>),
			width: 200,
			ellipsis: true,
			render: (event: AssessmentPitsburg4) => (
				<TableData>
					{event.name_of_problem}
				</TableData>
			),
		},
		{
			title: (<Span1>No bed partner or roommate</Span1>),
			width: 120,
			ellipsis: true,
			render: (event: AssessmentPitsburg4) => (
        <div style={{display:"flex",justifyContent:"center",width:"60%"}}>
				<StyledRadioButton
          selected={event.no_bed_partner_or_roommate}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"no_bed_partner_or_roommate",0,"eight")}
        >
          0
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>Partner/room mate in other room</Span1>),
			width: 120,
			ellipsis: true,
      render: (event: AssessmentPitsburg4) => (
        <div style={{display:"flex",justifyContent:"center",width:"60%"}}>
				<StyledRadioButton
          selected={event.partner_roommate_in_other_room}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"partner_roommate_in_other_room",1,"eight")}
        >
          1
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>Partner in same room but not same bed</Span1>),
			width: 130,
			ellipsis: true,
      render: (event: AssessmentPitsburg4) => (
        <div style={{display:"flex",justifyContent:"center",width:"70%"}}>
				<StyledRadioButton
          selected={event.partner_in_same_room_but_not_same_bed}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"partner_in_same_room_but_not_same_bed",2,"eight")}
        >
          2
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>Partner in same bed</Span1>),
			width: 120,
			ellipsis: true,
      render: (event: AssessmentPitsburg4) => (
        <div style={{display:"flex",justifyContent:"center",width:"60%"}}>
				<StyledRadioButton
          selected={event.partner_in_same_bed}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"partner_in_same_bed",3,"eight")}
        >
          3
        </StyledRadioButton>
        </div>
			),
		},
    
	];
  pitsburgColumn9:any = [
		{
			title: (<SpanName1></SpanName1>),
			width: 200,
			// ellipsis: true,
			render: (event: AssessmentPitsburg4) => (
				<TableData>	{event.name_of_problem}</TableData>
			),
		},
		{
			title: (<Span1>
        No bed partner or roommate
        </Span1>),
			width: 120,
			ellipsis: true,
			render: (event: AssessmentPitsburg4) => (
        <div style={{display:"flex",justifyContent:"center",width:"60%"}}>
				<StyledRadioButton
          selected={event.no_bed_partner_or_roommate} onClick={this.handlePitsburgSelect.bind(this,event.key,"no_bed_partner_or_roommate",0,"nine")}
        >
          0 </StyledRadioButton> </div>
			),
		},
    {
			title: (<Span1>Partner/room mate in other room</Span1>),
			width: 120,ellipsis: true,
      render: (event: AssessmentPitsburg4) => ( <div style={{display:"flex",justifyContent:"center",width:"50%"}}>
				<StyledRadioButton
          selected={event.partner_roommate_in_other_room}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"partner_roommate_in_other_room",1,"nine")}
        >
          1 </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>Partner in same room but not same bed</Span1>),
			width: 120,
			ellipsis: true,
      render: (event: AssessmentPitsburg4) => (
        <div style={{display:"flex",justifyContent:"center",width:"70%"}}>
				<StyledRadioButton selected={event.partner_in_same_room_but_not_same_bed}
          onClick={this.handlePitsburgSelect.bind(this,event.key,"partner_in_same_room_but_not_same_bed",2,"nine")}
        >  2</StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span1>Partner in same bed</Span1>),
			width: 120,
			ellipsis: true,
      render: (event: AssessmentPitsburg4) => (
        <div style={{display:"flex",justifyContent:"center",width:"60%"}}>
				<StyledRadioButton
          selected={event.partner_in_same_bed} onClick={this.handlePitsburgSelect.bind(this,event.key,"partner_in_same_bed",3,"nine")}
        >3 </StyledRadioButton>
        </div>
			),
		},
    
	];
 

  mfiscolumns:any = [
		{
			title: (<Span>Name Of Problem</Span>),
			width: 200,
			ellipsis: true,
			render: (event: AssessmentTest5) => (
				<TableData>
          <span style={{marginRight:"10px"}}> {event.key}.</span>
					{event.name_of_problem}
				</TableData>
			),
		},
    {
			title: (<Span>Never</Span>),
			width: 120,
			render: (event: AssessmentTest5) => (
        <div style={{display:"flex",justifyContent:"center",width:"30%"}}>
				<StyledRadioButton
          selected={event.never}
          onClick={this.handleSelect.bind(this,event.key,"never",0)}
        >
          0
        </StyledRadioButton>
        </div>
			),
		},
		{
			title: (<Span>Rarely</Span>),
			width: 120,
			render: (event: AssessmentTest5) => (
        <div style={{display:"flex",justifyContent:"center",width:"30%"}}>
				<StyledRadioButton
          selected={event.rarely}
          onClick={this.handleSelect.bind(this,event.key,"rarely",1)}
        >
          1
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span>Sometimes</Span>),
			width: 120,
      render: (event: AssessmentTest5) => (
        <div style={{display:"flex",justifyContent:"center",width:"50%"}}>
				<StyledRadioButton
          selected={event.sometimes}
          onClick={this.handleSelect.bind(this,event.key,"sometimes",2)}
        >
          2
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<Span>Often</Span>),
			width: 120,
      render: (event: AssessmentTest5) => (
        <div style={{display:"flex",justifyContent:"center",width:"35%"}}>
				<StyledRadioButton
          selected={event.often}
          onClick={this.handleSelect.bind(this,event.key,"often",3)}
        >
          3
        </StyledRadioButton>
        </div>
			),
		},
    {
			title: (<MfissSpan>Almost Always</MfissSpan>),
			width: 120,
      render: (event: AssessmentTest5) => (
        <div style={{display:"flex",justifyContent:"center",width:"60%"}}>
				<StyledRadioButton
          selected={event.almost_always}
          onClick={this.handleSelect.bind(this,event.key,"almost_always",4)}
        >
          4
        </StyledRadioButton>
        </div>
			),
		},
    
	];

 valueLabelFormat = (value:any) => {
  const mark = this.marks.find(mark => mark.value === value);
  if(mark && mark.value === 8){
    return "10"
  }
  else{
    return mark ? mark.label : value;
  }
};

  renderModalSubmit() {
		return (
			<AntDMoal
			  className="submit-modal"
			  open={this.state.showSubmitPopUp}
			  onCancel={this.hideSubmitModal}
			  footer={null}
			>
			<>
			  <div style={{
				padding: '30px 30px 15px 30px', fontSize: '24px',
				marginTop: '20px',fontWeight:700,letterSpacing:"-2%",boxSizing:"border-box"
			  }}>
          {this.state.testName === "Functional-Pain"?
			<span>	Are you sure you want to submit “ Effect of Functional Pain” assessment? </span>:
      <span>	Are you sure you want to submit “ { this.state.testName}” assessment? </span>
      }
			  </div>
			  <Divider className='divider' />
			  <div style={{ display: "flex", justifyContent: "right", padding: '0 22px 22px 22px' }} >
				  <Button
				 	data-testid="hide-app"
					style={{
					  height: "56px",
					  width: "120px",
					  background: "#ffff",
					  borderColor: "#ffff",
					  borderRadius: "8px",
					  color: "#830065",
            padding:"16px !important",
					  fontSize: '16px',
					  fontWeight: 700,
            textTransform:"none"
					}}
					onClick={this.hideSubmitModal} >Cancel</Button>
				<Button
					data-testid="submit-app"
					style={{
					  height: "56px",
					  width: "120px",
            whiteSpace: "nowrap",
					  background: "#830065",
					  borderColor: "#830065",
					  borderRadius: "8px",
					  color: "white",
					  fontSize: '14px',
					  fontWeight: 700,
            textTransform:"none",
					}}
					onClick={this.submitFunctinalPain} >Yes, Submit</Button>
				</div>
			</>
			</AntDMoal>
		);
	}
  renderMFISModalSubmit() {
		return (
			<AntDMFISMoal
			  className="submit-modal" open={this.state.showMFISPopUp}
			  onCancel={this.hideSubmitModal} footer={null}
			>
			<>
			  <div style={{
				padding: '30px 30px 15px 30px', fontSize: '24px',
				marginTop: '20px',fontWeight:700,letterSpacing:"-2%",boxSizing:"border-box" }}>
      <span>	Are you sure you want to submit “ { this.state.testName}” assessment? </span>
			  </div> <Divider className='divider' />
			  <div style={{ display: "flex", justifyContent: "right", padding: '0 22px 22px 22px' }} >
				  <Button
				 	data-testid="hide-app"
					style={{ height: "56px",width: "120px",background: "#ffff", borderColor: "#ffff", borderRadius: "8px",color: "#830065",
            padding:"16px !important", fontSize: '16px',fontWeight: 700,textTransform:"none"
					}}
					onClick={this.hideSubmitModal} >Cancel</Button>
				<Button
					data-testid="submit-app"
					style={{  height: "56px",
					  width: "120px",whiteSpace: "nowrap",background: "#830065",borderColor: "#830065",
					  borderRadius: "8px",color: "white",fontSize: '14px',fontWeight: 700,textTransform:"none",
					}}
					onClick={this.submitFunctinalPain} >Yes, Submit</Button>
				</div>	</></AntDMFISMoal>);
	}
  renderPitsburghModalSubmit() {
		return (
			<AntDMFISMoal
			  className="submit-modal" open={this.state.pitsModal}
			  onCancel={this.hideSubmitModal} footer={null}
			>
			<>
			  <div style={{
				padding: '30px 30px 25px 30px', fontSize: '24px',marginTop: '20px',fontWeight:700,letterSpacing:"-2%",boxSizing:"border-box" }}>
      <span>	Are you sure you want to submit “ { this.state.testName}” assessment? </span>
			  </div> <Divider className='divider' /> <div style={{ display: "flex", justifyContent: "right", padding: '0 22px 22px 22px' }} >
				  <Button
				 	data-testid="hide-app"
					style={{ height: "56px",width: "120px",background: "#ffff", borderColor: "#ffff", borderRadius: "8px",color: "#830065",
            padding:"16px !important", fontSize: '16px',fontWeight: 700,textTransform:"none"
					}} onClick={this.hideSubmitModal} >Cancel</Button>
				<Button
					data-testid="submit-app"
					style={{  height: "56px",
					  width: "120px",whiteSpace: "nowrap",background: "#830065",borderColor: "#830065", borderRadius: "8px",color: "white",
            fontSize: '14px',fontWeight: 700,textTransform:"none",
					}}
					onClick={this.submitPitsData} >Yes, Submit</Button>
				</div>	</></AntDMFISMoal>);
	}
  renderAfterSubmit() {
		return (
			<AntDMFISMoal2 className="submit-modal" open={this.state.afterSubmit}
			  onCancel={this.hideSubmitModal} footer={null}
			>
			<>
			  <div style={{padding: '30px 30px 15px 30px', fontSize: '24px',
				marginTop: '20px',fontWeight:700,letterSpacing:"-2%",boxSizing:"border-box" }}>
      <span>	Test has been submitted to the case manager for review. </span>
			  </div> <Divider className='divider' />
			  <div style={{ display: "flex", justifyContent: "right", padding: '0 22px 5px 22px' }} >
				<Button
					data-testid="submit-app"
					style={{  height: "56px",
					  width: "120px",whiteSpace: "nowrap",background: "#830065",borderColor: "#830065",
					  borderRadius: "8px",color: "white",fontSize: '14px',fontWeight: 700,textTransform:"none",
					}}
					onClick={this.goBacktoListing} >OK</Button>
				</div>	</></AntDMFISMoal2>);
	}
  renderAcceptRedoModal() {
		return (
			<AntDMFISMoal
			  className="submit-modal" open={this.state.showAcceptRedo}
			  onCancel={this.hideSubmitModal} footer={null}
			>
			<>
			  <div style={{
				padding: '30px 30px 15px 30px', 
        fontSize: '24px',
				marginTop: '20px',fontWeight:700,letterSpacing:"-2%",boxSizing:"border-box"
			  }}>
          {
            this.state.checkClick ?
            <span>	Are you sure you want to accept “ { this.getTestName()}” test? </span>
            :
            <span>	Are you sure you want to mark “ { this.getTestName()} as redo?”</span>


          }
     
			  </div>
			  <Divider 
        className='divider' />
			  <div style={{ display: "flex", 
        justifyContent: "right", padding: '0 22px 22px 22px' }} >
				  <Button
				 	data-testid="hide-app"
					style={{
					  height: "56px",width: "120px",
					  background: "#ffff", borderColor: "#ffff",
					  borderRadius: "8px",
					  color: "#830065",
            padding:"16px !important", fontSize: '16px',
					  fontWeight: 700,textTransform:"none"
					}}
					onClick={this.hideSubmitModal} >Cancel</Button>
				<Button
					data-testid="submit-app"
					style={{  height: "56px",
					  width: "120px",whiteSpace: "nowrap",
					  background: "#830065",borderColor: "#830065",
					  borderRadius: "8px",
					  color: "white",fontSize: '14px',
					  fontWeight: 700,textTransform:"none",
					}}
					onClick={this.submitAcceptData} >{this.state.checkClick ? "Yes, Accept" : "Yes, Redo"}</Button>
				</div>
			</>
			</AntDMFISMoal>
		);
	}
  getSliderValueData= (event:any,value:any)=>{
    const selelcted= this.valueLabelFormat(value) 
    this.setState({functionalPainScore:selelcted})
  }

  renderFunctionalPain = ()=>{
    return <PageWrapper  title={this.title} hasBack={true}>
    <MainContainer>
     <Container>
     <HeadingContainer>
       <H3>
         Functional Pain test
       </H3>
       <P>Please select the correct score from the available options.</P>
     </HeadingContainer>
     <Divider style={{color:"#E2E8F0", height:"1px", border:"1px solid #E2E8F0"}} />
       <HeadingContainer>
         <H4>
           Effect of Functional Pain
         </H4>
       
           <div style={{ width: '100%', padding: '20px 20px 20px 0' }}>
            {
              this.state.userType === "client" ? 

              <CustomSlider
              onChange={(event, value) => this.getSliderValueData(event, value)}
              step={null}
              marks={this.marks}
              min={0}
              max={8}
              valueLabelFormat={this.valueLabelFormat}
            />
              :
              <AdminSlider
              step={null}
              value={this.state.functionalScore ? this.state.functionalScore : 0}
              marks={this.marks}
              min={0}
              max={8}
              valueLabelFormat={this.valueLabelFormat}
            />

            }
           
           </div>
       </HeadingContainer>
     <Divider style={{color:"#E2E8F0", height:"1px", border:"1px solid #E2E8F0"}} />
     <HeadingContainer>
       <H2 style={{marginBottom:"20px",textTransform:"uppercase"}}>
         Functional Pain.pdf
       </H2>
       <P1>10</P1>
       <P1>Worst imaginable pain. Causes you to be completely incapacitated and barely able to talk. Requires immediate emergency hospitalisation</P1>
       <P1>8-9</P1>
       <P1>Pain that causes disability between levels 7 and 10. Nearing need for hospitalisation</P1>
       <P1>7</P1>
       <P1>Severely disabling pain. You cannot use or move the painful area. You have difficulty talking and concentrating on anything but the pain. Needing to lie down  and/or pain-related  tearfulness are common at this level of pain</P1>
       <P1>6</P1>
       <P1>Pain that causes disability between levels 5 and 7</P1>
       <P1>5</P1>
       <P1>Very disabling pain. Causes great difficulty moving or applying any strength through the painful area. You are unable to complete the current activity</P1>
     <P1>4</P1>
     <P1>Pain that causes disability between levels 3 and 5</P1>
     <P1>3</P1>
     <P1>Functionally disabling pain. Pain that is starting to affect your ability to perform the current activity, (i.e. decreased movement, decreased speed and/or the need to briefly rest and/or stretch in order to continue completing the current activity).</P1>
     <P1>0.25 to 2.75</P1>
     <P1>Non disabling pain.The pain is present, but not yet at a level which limits you from performing the current activity.</P1>
     <P1>0</P1>
     <P1>No pain or discomfort</P1>
     </HeadingContainer>
     <ButtonContainer>
      {
        this.state.userType === "client" ?
        <SubmitButton
             onClick={this.openSubmitPopUp}
           >
             Submit
           </SubmitButton>
      :
      <>
       <SubmitButton  onClick={()=>this.showAcceptRedoModal("accept")}
      >
        Accept
      </SubmitButton>
        <RedoButton onClick={()=>this.showAcceptRedoModal("redo")}
        >
        Redo
      </RedoButton>
        </>
      }
    
     </ButtonContainer>
     </Container>
   </MainContainer>

 </PageWrapper> 
  }
  getTestName=()=>{
    if(this.state.testName === "GAD-7-Anxiety" )
    {
      return  "GAD-7 Anxiety"
    }
    if(this.state.testName === "Modified-Fatigue-Impact-Scale" )
    {
      return  "Modified Fatigue Impact Scale"
    }
    if(this.state.testName === "Pittsburgh-Sleep-Quality-Index" )
    {
      return  "Pittsburgh Sleep Quality Index"
    }
    else {
      return "PHQ 9 Depression"
    }
  }
  renderQuestionTest = ()=>{
    return <PageWrapper  title={this.title} hasBack={true}>
    <MainContainer>
     <Container>
     <HeadingContainer>
       <H3>
         {this.getTestName()}
       </H3>
       <P>Please select the correct score from the available options.</P>
     </HeadingContainer>
     <div style={{ width: '100%', overflowX: 'auto' }}>
     <div style={{ display: 'inline-block', minWidth: '100%' }}>
     <TableDesign
       data-testid="AssessmentTable"
       columns={this.columns}
       bordered
       dataSource={this.getTableColumn()}
      scroll={{ x: 'max-content' }} 
       pagination={false}
     />
     {
     this.state.userType !== "client" ?
     <>
       <ResultContainer>
        <InnerResult>
        <Scorediv>
          <Numdiv>
            {this.state.resultData && this.state.resultData.zero_count }
          </Numdiv>
          <Line>

          </Line>
        </Scorediv>
        <Add>
          +
        </Add>
        <Scorediv>
          <Numdiv>
          {this.state.resultData && this.state.resultData.one_count }
          </Numdiv>
          <Line>

          </Line>
        </Scorediv>
        <Add>
          +
        </Add>
        <Scorediv>
          <Numdiv>
          {this.state.resultData && this.state.resultData.two_count }
          </Numdiv>
          <Line>

          </Line>
        </Scorediv>
        <Add>
          +
        </Add>
        <Scorediv>
          <Numdiv>
          {this.state.resultData && this.state.resultData.three_count }
          </Numdiv>
          <Line>

          </Line>
        </Scorediv>
        </InnerResult>
       </ResultContainer>
       <ResultContainer2>
       <Add2>
          = Total Score
        </Add2>
        <Scorediv>
          <Numdiv>
          {this.state.resultData && this.state.resultData.total_count }
          </Numdiv>
          <Line />
        </Scorediv>
       </ResultContainer2>
       </>
       :
       null
      }
      </div>
      </div>
        <ButtonContainer>
      {
        this.state.userType === "client" ?
        <SubmitButton
            disabled={this.state.submitTime}
             onClick={this.openSubmitPopUp}
           >
             Submit
           </SubmitButton>
      :
      <>
       <SubmitButton disabled={this.state.userSubmitData===null || this.state.userSubmitData.length<=0} onClick={()=>this.showAcceptRedoModal("accept")}>
        Accept
      </SubmitButton>
        <RedoButton onClick={()=>this.showAcceptRedoModal("redo")}
        >
        Redo
      </RedoButton>
        </>
      }
     </ButtonContainer>
     </Container>
   </MainContainer>

 </PageWrapper> 

  }
  renderMFIS = ()=>{
    return  <PageWrapper  title={this.title} hasBack={true}>
    <MainContainer>
     <Container>
     <HeadingContainer>
       <H3>
         {this.getTestName()}
       </H3>
       <P>Please select the correct score from the available options.</P>
     </HeadingContainer>
     <TableDesign
       data-testid="AssessmentTable"
       columns={this.mfiscolumns}
       bordered
       dataSource={this.getTableColumn()}
       scroll={{ x: 'max-content',y:500 }} 
       pagination={false}
     />
     {
      this.state.userType !=="client" &&
<FooterContainer>
            <MainDiv>
              <Div1>Instructions for Scoring the MFIS</Div1>
              <Div2>Items on the MFIS can be aggregated into three subscales (physical, cognitive, and
                psychosocial), as well as into a total MFIS score. All items are scaled so that higher
                scores indicate a greater impact of fatigue on a person’s activities.
              </Div2>
            </MainDiv>
            <Divider />
            <ContainerDiv>
            <MainDiv2>
              <Div1>Physical Subscale</Div1>
              <Div2>This scale can range from 0 to 36.</Div2>
            </MainDiv2>
            <MainDiv3>
                <Numdiv>
                 {this.state.resultData?.physical_subscale}
                </Numdiv>
                 <Line />
                </MainDiv3>
            </ContainerDiv>
            <ContainerDiv>
            <MainDiv2>
              <Div1>Cognitive Subscale</Div1>
              <Div2>This scale can range from 0 to 40.</Div2>
            </MainDiv2>
            <MainDiv3>
                <Numdiv>
                {this.state.resultData?.cognitive_subscale}
                </Numdiv>
                 <Line />
                </MainDiv3>
            </ContainerDiv>
            <ContainerDiv>
            <MainDiv2>
              <Div1>Psychosocial Subscale</Div1>
              <Div2>This scale can range from 0 to 8.</Div2>
            </MainDiv2>
            <MainDiv3>
                <Numdiv>
                {this.state.resultData?.psychosocial_subscale}
                </Numdiv>
                 <Line />
                </MainDiv3>
            </ContainerDiv>
            <ContainerDiv>
            <MainDiv2>
              <Div1>Total MFIS Score</Div1>
                <Div3>The total MFIS score can range from 0 to 84. It is computed by adding
                  scores on the physical, cognitive, and psychosocial subscales.</Div3>
            </MainDiv2>
            <MainDiv3>
                <Numdiv>
                {this.state.resultData?.total_count}
                </Numdiv>
                 <Line />
                </MainDiv3>
            </ContainerDiv>


          </FooterContainer>
     }
          
          <ButtonContainer>
            {
              this.state.userType === "client" ?
                <SubmitButton
                  onClick={this.openMFISPopUp}
                >
                  Submit
                </SubmitButton>
                :
                <>
                  <SubmitButton disabled={ this.state.userSubmitData === null || this.state.userSubmitData.length <= 0 } onClick={()=>this.showAcceptRedoModal("accept")}>
                    Accept
                  </SubmitButton>
                  <RedoButton onClick={()=>this.showAcceptRedoModal("redo")}
                  >
                    Redo
                  </RedoButton>
                </>
            }

          </ButtonContainer>
     </Container>
   </MainContainer>

 </PageWrapper> 
  }
  dropdownStyle = {
		border: '1px solid #CBD5E1 ',
		borderRadius: "10px",
		padding:"0px" ,
		marging:"0px",

	  };

    getPopupContainer = (trigger:any)=>{
      return trigger.parentElement
    }
    selectedValue = (data:any) => {
      const selectedOption = this.options.find(option => option.value === data);
      if (selectedOption) {
        const { key, label } = selectedOption;
        this.setState({pitsburgData1:this.state.pitsburgData1.map((tableData)=>{
          if(tableData.key === key){
            return {
              key: key,
              name_of_problem: "During the past month, what time have you usually gone to bed at night?",
              assessment_id:localStorage.getItem("AssessmentId"),
              answer:label,
              value:data
              }
          }
          return tableData
        }),countClicks:this.state.countClicks + 1,columnOne:data})
      }
    };
    selectedValue2 = (data:any) => {
      const selectedOption = this.options2.find(option => option.value === data);
      if (selectedOption) {
        const { key, label } = selectedOption;
        this.setState({pitsburgData1:this.state.pitsburgData1.map((tableData)=>{
          if(tableData.key === key){
            return {
              key: key,
              name_of_problem: "During the past month, how long (in minutes) has it usually taken you to fall asleep each night?",
              assessment_id:localStorage.getItem("AssessmentId"),
              answer:label,
              value:data
              }
          }
          return tableData
        }),countClicks:this.state.countClicks + 1,columnTwo:data})
      }
    };
    selectedValue4 = (data:any) => {
      const selectedOption = this.options4.find(option => option.value === data);
      if (selectedOption) {
        const { key, label } = selectedOption;
        this.setState({pitsburgData1:this.state.pitsburgData1.map((tableData)=>{
          if(tableData.key === key){
            return {
              key: key,
              name_of_problem: "During the past month, how many hours of actual sleep did you get at night? (This may be different than the number of hours you spent in bed.)",
              assessment_id:localStorage.getItem("AssessmentId"),
              answer:label,
              value:data
              }
          }
          return tableData
        }),countClicks:this.state.countClicks + 1,columnFour:data })
      }
    };
    selectedValue3 = (data:any) => {
      const selectedOption = this.options3.find(option => option.value === data);
      if (selectedOption) {
        const { key, label } = selectedOption;
        this.setState({pitsburgData1:this.state.pitsburgData1.map((tableData)=>{
          if(tableData.key === key){
            return {
              key: key,
              name_of_problem: "During the past month, what time have you usually gotten up in the morning?",
              assessment_id:localStorage.getItem("AssessmentId"),
              answer:label,
              value:data
              }
          }
          return tableData
        }),countClicks:this.state.countClicks + 1,columnThree:data})
      }
    };

    renderPitsubrghAcceptModal=()=>{
      this.showAcceptRedoModal("accept")
    }
    renderPitsubrghRejectModal=()=>{
      this.showAcceptRedoModal("redo")
    }
  renderPitsburghTest = ()=>{
    return <PageWrapper  title={this.title} hasBack={true}>
    <MainContainer>
     <Container>
     <HeadingContainer>
       <H3>
         {this.getTestName()}
       </H3>
       <P>The following questions relate to your usual sleep habits during the past month only. Your answers should indicate the most accurate reply for the majority of days and nights in the past month.<b> Please answer all questions</b>.</P>
     </HeadingContainer>
     <QuestionFirstContainer>
        <InnerConatiner>
        During the past month, what time have you usually gone to bed at night?
        </InnerConatiner>
        <div style={{height:"50px"}}>
								<SelectBox
									id="select-type" className="apt-design font-style customFiled"
									placeholder="Select Status"
									dropdownStyle={this.dropdownStyle}
                  value={this.state.columnOne}
									onChange={this.selectedValue}
									options={[ ...this.options
                  ]}
									suffixIcon={dropdown}
									getPopupContainer={this.getPopupContainer}
								/>

								</div>
     </QuestionFirstContainer>
     <QuestionFirstContainer>
        <InnerConatiner>
        During the past month, how long (in minutes) has it usually taken you to fall asleep each night?
        </InnerConatiner>
        <div style={{height:"50px"}}>
								<SelectBox
									id="select-type" className="apt-design font-style customFiled"
									placeholder="Select Status"
									dropdownStyle={this.dropdownStyle}
                  value={this.state.columnTwo}
									onChange={this.selectedValue2}
									options={[ ...this.options2
                  ]}
									suffixIcon={dropdown}
									getPopupContainer={this.getPopupContainer}
								/>

								</div>
     </QuestionFirstContainer>
     <QuestionFirstContainer>
        <InnerConatiner>
        During the past month, what time have you usually gotten up in the morning?
        </InnerConatiner>
        <div style={{height:"50px"}}>
								<SelectBox
									id="select-type" className="apt-design font-style customFiled"
									placeholder="Select Status"
									dropdownStyle={this.dropdownStyle}
									onChange={this.selectedValue3}
                  value={this.state.columnThree}
									options={[ ...this.options3
                  ]}
									suffixIcon={dropdown}
									getPopupContainer={this.getPopupContainer}
								/>

								</div>
     </QuestionFirstContainer>
     <QuestionFirstContainer>
        <InnerConatiner>
        During the past month, how many hours of actual sleep did you get at night? (This may be different than the number of hours you spent in bed.)
        </InnerConatiner>
        <div style={{height:"50px"}}>
								<SelectBox
									id="select-type" className="apt-design font-style customFiled"
									placeholder="Select Status"
									dropdownStyle={this.dropdownStyle}
                  value={this.state.columnFour}
									onChange={this.selectedValue4}
									options={[ ...this.options4
                  ]}
									suffixIcon={dropdown}
									getPopupContainer={this.getPopupContainer}
								/>

								</div>
     </QuestionFirstContainer>
     <TableDesign2
       data-testid="AssessmentTable"
       columns={this.pitsburgColumn5}
       bordered
       dataSource={this.getPitsburghData("First")}
       scroll={{ x: 'max-content' }} 
       pagination={false}
     />
       <TableDesign2
       data-testid="AssessmentTable"
       columns={this.pitsburgColumn6}
       bordered
       dataSource={this.getPitsburghData("Second")}
       scroll={{ x: 'max-content'}} 
       pagination={false}
     />
         <TableDesign2
       data-testid="AssessmentTable"
       columns={this.pitsburgColumn7}
       bordered
       dataSource={this.getPitsburghData("Third")}
       scroll={{ x: 'max-content'}} 
       pagination={false}
     />
         <TableDesign2
       data-testid="AssessmentTable"
       columns={this.pitsburgColumn8}
       bordered
       dataSource={this.getPitsburghData("Four")}
       scroll={{ x: 'max-content'}} 
       pagination={false}
     />
           <TableDesign2
       data-testid="AssessmentTable"
       columns={this.pitsburgColumn9}
       bordered
       dataSource={this.getPitsburghData("Five")}
       scroll={{ x: 'max-content' }} 
       pagination={false}
     />
        {this.state.userType !== "client" ?
            <>
              <ContainerDiv2>
                <MainDiv2>
                  <Div1>Scoring the PSQI</Div1>
                  <Div2>In scoring the PSQI, seven component scores are derived, each scored 0 (no difficulty) to 3 (severe difficulty).
                    The component scores are summed to produce a global score (range 0 to 21). Higher scores indicate worse sleep quality.</Div2>
                </MainDiv2>
              </ContainerDiv2>
              <Divider className='divider' />
              <ContainerDiv2>
                <MainDiv2>
                  <Div1>Global PSQI Score</Div1>
                  <Div2>Sum of seven component scores.</Div2>
                </MainDiv2>
                <MainDiv3>
                  <Numdiv>
                 { this.state.totalPitsScore}
                  </Numdiv>
                  <Line />
                </MainDiv3>
              </ContainerDiv2>
            </>
            : null
          }
        <ButtonContainer>
      {
        this.state.userType === "client" ?
        <SubmitButton
             onClick={this.openSubmitPitsPopUp}
           >
             Submit
           </SubmitButton>
      :
      <>
       <SubmitButton  data-testid="showAccept" disabled={this.state.pitsTableFirst.length <=0} onClick={this.renderPitsubrghAcceptModal}>
        Accept
      </SubmitButton>
        <RedoButton  data-testid="showReject" onClick={this.renderPitsubrghRejectModal}
        >
        Redo
      </RedoButton>
        </>
      }
     </ButtonContainer>
     </Container>
   </MainContainer>
 </PageWrapper> 

  }

  // Customizable Area End


  render() {
    // Customizable Area Start
    return (
      <>
       {
        (() => {
          switch (this.state.testName) {
            case "GAD-7-Anxiety":
              return this.renderQuestionTest();
              case "Pittsburgh-Sleep-Quality-Index":
                return this.renderPitsburghTest();
                case "Modified-Fatigue-Impact-Scale":
                  return this.renderMFIS()
                  case "PHQ-9-Depression":
                  return this.renderQuestionTest();
            default:
              return this.renderFunctionalPain(); 
          }
        })()
      }
      {this.renderModalSubmit()}
      {this.renderMFISModalSubmit()}
      {this.renderAcceptRedoModal()}
      {this.renderPitsburghModalSubmit()}
      {this.renderAfterSubmit()}
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  inner: {
    position: "relative",
    border: "2px solid rgb(227, 227, 227)",
    borderRadius: "0 0 6px 6px",
    padding: "12px",
    display: "flex",
    gap: "12px",
    flexWrap: "wrap",
  },
  question: {
    background: "linear-gradient(60deg, rgb(172, 172, 172), rgb(238, 238, 238), rgb(172, 172, 172))",
    padding: "12px",
    borderRadius: "8px",
    boxShadow: "5px 5px 15px rgb(52, 52, 52)",
    fontSize: "1.2em",
    display: "flex",
    justifyContent: " space-between",
  },
  options: {

    gap: "12px",
  },
  option: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },

  descripInput:{
    width: "400px",
    minHeight: "40px",
    borderRadius: "8px",
    fontSize: "1.2em",
  },
  count: {
    position: "relative",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    background: " rgb(109, 109, 109)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
}
const MainContainer = styled("div")({
  marginTop: "20px",
  background: "white",
  borderRadius: "8px 8px 32px 8px",

});

const QuestionFirstContainer = styled("div")(({theme})=>({
 display:"flex",
 flexDirection:"column",
 width:"100%",
 padding:"5px 0 0 24px"
  })
)
const InnerConatiner = styled("p")(({theme})=>({
fontSize:"14px",
fontWeight:400
   })
 )

const Container = styled("div")({
  width:"100%",
  });
const HeadingContainer = styled("div")({
width:"100%",
padding:"21px 0 0 24px"
});
const H3 = styled("h3")(({theme})=>({
  fontSize:"16px",
  fontWeight:700,
  color:"#334155",
  [theme.breakpoints.down("xs")]:{
  fontSize:"14px",
  }
})
)

const H2 = styled("h2")(({theme})=>({
  fontSize:"16px",
  fontWeight:700,
  color:"#0F172A",
  [theme.breakpoints.down("xs")]:{
    fontSize:"14px",
    }
})
)

const H4 = styled("h3")(({theme})=>({
  fontSize:"14px",
  fontWeight:700,
  color:"#334155",
  [theme.breakpoints.down("xs")]:{
    fontSize:"14px",
    }
})
)
const H5 = styled("h5")(({theme})=>({
  fontSize:"16px",
  fontWeight:600,
  color:"#334155",
  [theme.breakpoints.down("xs")]:{
    fontSize:"13px",
    }
})
)
const P = styled("p")(({theme})=>({
  fontSize:"14px",
  fontWeight:400,
  color:"#334155",
  [theme.breakpoints.down("xs")]:{
  fontSize:"12px",
  }
})
)
const P1 = styled("p")(({theme})=>({
  fontSize:"16px",
  fontWeight:400,
  color:"#334155",
  marginBottom:"5px",
  [theme.breakpoints.down("xs")]:{
    fontSize:"12px",
    }
})
)
const FooterContainer = styled("div")({
  display:"flex",
  flexDirection:"column",
  margin:"40px 0px 100px 0px",
  paddingLeft:"24px"
})
const MainDiv = styled("div")({
  display:"flex",
  flexDirection:"column",
  width:"95%",
  margin:"10px 0px 20px 0px"
})
const MainDiv3 = styled("div")({
  display:"flex",
  flexDirection:"column",
  width:"65px",
  justifyContent:"center",
  alignItems:"center"
})
const Div1 = styled("div")({
color:"#0F172A",
fontSize:"14px",
fontWeight:700
}) 
const Div2 = styled("div")(({theme})=>({
  color:"#0F172A",
  fontSize:"14px",
  fontWeight:400,
  textAlign:"justify",
  paddingRight:"10px",
  [theme.breakpoints.down("xs")]:{
    fontSize:"12px",
  }
})
)

const Div3 = styled("div")(({theme})=>({
  color:"#0F172A",
  fontSize:"14px",
  fontWeight:400,
  textAlign:"justify",
  paddingRight:"10px",
  [theme.breakpoints.down("xs")]:{
    width:"200px",
    fontSize:"12px",
  }
})
)

const ContainerDiv = styled("div")({
  display:"flex", 
  margin:"15px 0px",
  justifyContent:"space-between",
  width:"98%"
});
const ContainerDiv2 = styled("div")({
  display:"flex",
  margin:"15px 0px",
  padding:"0px 22px",
  justifyContent:"space-between",
  width:"98%"
});
const MainDiv2 = styled("div")({
  display:"flex",
  flexDirection:"column",

});
const Customleftspan =styled("span")(({theme})=>({ 
   marginLeft:"20px",
  [theme.breakpoints.down("xs")]:{
    marginLeft:"0px",
  }}
  )
)

const Customrightspan =styled("span")(({theme})=>({ 
   marginRight:"55px",
  [theme.breakpoints.down("xs")]:{
    marginRight:"0px",
  }}
  )
)
const ButtonContainer = styled("div")({
 width:"100%",
 display:"flex",
 justifyContent:"center",
 alignItem:"center",
 margin:"50px 0px 0px 0px",
 paddingBottom:"30px",
});
const Scorediv = styled("div")({
 display:"flex",
 flexDirection:"column",
 alignItems:"center",
 justifyContent:"center",
 width:"65px",
});
const Add = styled("div")({
  display:"flex",
  alignItems:"flex-end",
  justifyContent:"center",
  width:"65px",
  height:"50px",
  fontSize:"20px",
  fontWeight:400,
 });
 const Add2 = styled("div")({
  display:"flex",
  alignItems:"flex-end",
  justifyContent:"center",
  width:"100px",
  height:"45px",
  fontSize:"14px",
  fontWeight:400,
  margin:"0px 2px 5px 0px"
 });
const Numdiv = styled("div")({
  fontSize:"14px",
  fontWeight:700
});
const Line = styled(Divider)({
backgroundColor:"#000000",
height:"1px",
padding:0,
margin:"5px 0px 0px 0px"
});
const AntDMoal = styled(Modal)({
  top:"200px",
  "& .ant-modal-content":{
    height:"252px",
    width:"600px"
  },
  "& .ant-modal-close":{
    margin:"20px 5px 0px 0px"
  }
})
const AntDMFISMoal = styled(Modal)({
  top:"200px",
  "& .ant-modal-content":{
    minheight:"252px !important",
    width:"600px"
  },
  "& .ant-modal-close":{
    margin:"20px 5px 0px 0px"
  }
})
const AntDMFISMoal2 = styled(Modal)({
  top:"200px",
  "& .ant-modal-content":{
    height:"252px !important",
    width:"600px"
  },
  "& .ant-modal-close":{
    margin:"20px 5px 0px 0px"
  }
})
const SubmitButton = styled(Button)(({theme})=>({
  height: "3.5rem",
  width: '5.5rem',
  color: "white",
  background:"#830065",
  borderColor: "#830065",
  borderRadius: "8px",
  padding:"16px",
  fontWeight: 700,
  fontSize: "16px",
  display:"flex",
  justifyContent:"center",
  alignItem:"center",
  textTransform:"none",

  "&:hover": {
    background: "#830065 !important",
    borderColor: "#830065 !important",
  }, 
  "&:active": {
    background: "#830065 !important",
    borderColor: "#830065 !important",
  },
  "&:visited": {
    background: "#830065 !important",
    borderColor: "#830065 !important",
  },
  [theme.breakpoints.down("xs")]:{
    height: "3rem",
    width: '5rem',
    fontWeight: 700,
    fontSize: "12px",
  },
  '&.Mui-disabled':{
    color:"white",
  }
 })
)

 const  ResultContainer = styled("div")({
  display:"flex",
  justifyContent:"flex-end",
  marginTop:"2rem",
  width:"94%",
 })
 const  InnerResult = styled("div")({
  display:"flex",
  justifyContent:"space-around",
  width:"65%",
 })

 const  ResultContainer2 = styled("div")(({theme})=> ({
  display:"flex",
  justifyContent:"center",
  marginTop:"2rem",
  width:"100%",
  paddingRight:"50px",
  [theme.breakpoints.down("md")]:{
    marginBottom:"30px"
  }
 })
 )

const CustomSlider = styled(Slider)({
  color: '#830065', 
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    height:"12px",
    borderRadius:"40px",
  },
  '& .MuiSlider-rail': {
    height:"12px",
    color:"#EAECF0 !important",
    backgroundColor:"#EAECF0 !important",
    borderRadius:"40px",
  },

  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },

"& .MuiSlider-mark":{
  width:"0px",
  height:"0px",
  backgroundColor:"current-color"
},

  '& .MuiSlider-markLabel': {
    top: "26px !important",
    color: "rgba(0, 0, 0, 0.54) !important",
    position: "absolute" as "absolute",
    fontSize: "0.875rem !important",
    transform: " translateX(-50%) !important",
    whiteSpace: "nowrap " as "nowrap",
    letterSpacing: "0.01071em !important",
    paddingLeft: "10px",
  },

markLabel: {
  top: "50px !important", 
  transform: 'translateX(-50%)',
  whiteSpace: 'nowrap',
},

});
const AdminSlider = styled(Slider)({
  color: '#94A3B8', height: 8,
  '& .MuiSlider-track': {
    border: 'none', height:"12px",
    borderRadius:"40px",
  },
  '& .MuiSlider-rail': {
    height:"12px",
    color:"#EAECF0 !important", backgroundColor:"#EAECF0 !important", borderRadius:"40px",
  },

  '& .MuiSlider-thumb': {
    height: 24, width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {boxShadow: 'inherit', },
    '&:before': { display: 'none', },
  },

"& .MuiSlider-mark":{
  width:"0px", height:"0px", backgroundColor:"current-color"
},

  '& .MuiSlider-markLabel': {
    top: "26px !important", color: "rgba(0, 0, 0, 0.54) !important",
    position: "absolute" as "absolute", fontSize: "0.875rem !important",
    transform: " translateX(-50%) !important", whiteSpace: "nowrap " as "nowrap",
    letterSpacing: "0.01071em !important",paddingLeft: "10px",
  },
markLabel: {
  top: "50px !important",  transform: 'translateX(-50%)', whiteSpace: 'nowrap',
},

});
const TableDesign = styled(Table)({
  borderBottomLeftRadius: "8px" ,
  borderBottomRightRadius: "32px",
  overflow: "hidden",
  "& .ant-table-thead .ant-table-cell": {
    borderBottom: "1px solid #E2E8F0 !important",
    background: "#F1F5F9 !important",
  }
})
const TableDesign2 = styled(Table)({
  borderBottomLeftRadius: "8px" ,
  borderBottomRightRadius: "32px",
  overflow: "hidden",
  "& .ant-table-thead .ant-table-cell": {
    borderBottom: "1px solid #E2E8F0 !important",
    background: "#F1F5F9 !important",
  },
  "& .ant-table-thead > tr > th":{
    padding:"10px 16px !important"
  }
})
const Span = styled("span")({
  color:"#0F172A",
  fontSize: "14px",
  fontWeight: 700,
  marginTop: "4px",
  whiteSpace: "nowrap",
})
const MfissSpan = styled("span")({
  color:"#0F172A",
  fontSize: "14px",
  fontWeight: 700,
  marginTop: "4px",
})
const Span1 = styled("span")(({theme})=>({
  color:"#0F172A",
  fontSize: "14px",
  fontWeight: 700,
  WebkitLineClamp: 2,
  overflow: "hidden",
  whiteSpace: "normal",
  [theme.breakpoints.down('xs')]:{
    whiteSpace: "nowrap",
  }
})
)
const Span4 = styled("span")(({theme})=>({
  color:"#0F172A",
  fontSize: "14px",
  fontWeight: 700,
  WebkitLineClamp: 2,
  overflow: "hidden",
  whiteSpace: "normal",
  "&.ant-table-thead > tr > th":{
    textAlign:"center"
  },

  [theme.breakpoints.down('xs')]:{
    whiteSpace: "nowrap",
  }
})
)

const SpanName = styled("span")({
  color:"#0F172A",
  fontSize: "14px",
  fontWeight: 700,
  marginTop: "4px",
  marginLeft:"10px",
})
const SpanName1 = styled("span")(({theme})=>({
  color:"#0F172A",
  fontSize: "14px",
  fontWeight: 700,
  marginTop: "4px",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  whiteSpace: "normal",
  [theme.breakpoints.down('xs')]:{
    overflow: "hidden",
    whiteSpace: "nowrap",
  }
})
)


const TableData = styled("span")(({theme})=>({
  color: "#000",
  fontSize: "14px",
  fontWeight: 400,
  textAlign:"justify",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  whiteSpace: "normal",
  paddingLeft:"10px",
  [theme.breakpoints.down('xs')]:{
    whiteSpace: "nowrap",
  }
})
)
const FunctionalSpanLast = styled("span")(({theme})=>({
  marginRight:"40px",
  [theme.breakpoints.down("xs")]:{
    marginRight:"25px",
  }
 })
)
const FunctionalSpanFirst = styled("span")(({theme})=>({
  marginLeft:"10px",
  [theme.breakpoints.down("xs")]:{
    marginLeft:"1px",
  }
 })
)
const RedoButton = styled(Button)(({theme})=>({
  height: "3.5rem",
  width: '4.5rem',
  color: "white",
  marginLeft:"2rem",
  background: "#FF9595",
  borderColor: "#FF9595",
  borderRadius: "0.5rem",
  padding:"1rem",
  fontWeight: 700,
  fontSize: "1rem",
  display:"flex",
  justifyContent:"center",
  alignItem:"center",
  textTransform:"none",

  "&:hover": {
    background: "#830065 !important",
    borderColor: "#830065 !important",
  }, 
  "&:active": {
    background: "#830065 !important",
    borderColor: "#830065 !important",
  },
  "&:visited": {
    background: "#830065 !important",
    borderColor: "#830065 !important",
  },
  [theme.breakpoints.down("xs")]:{
    height: "3rem",
    width: '4rem',
    fontSize: "12px",
  }

 })
)

const StyledRadioButton = styled('span')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '25px',
  height: '25px',
  backgroundColor: (props:any) => (props.selected ? '#830065' : 'white'),
  color: (props) => (props.selected ? 'white' : 'black'),
  borderRadius: '50% !important',
  fontWeight: (props) => (props.selected ? 700 : 400),
  border: (props) => (props.selected ? '2px solid #a62c7e' :"" ),
  cursor: 'pointer',
  fontSize: (props) => (props.selected ? '14px' : '12px'),
  padding: '2px',
});
const SelectBox = styled(Select)(({theme})=>({
    width:"200px",
    height:"32px"
     })
   )

// Customizable Area End
